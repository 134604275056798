import React, {useEffect, useState} from 'react';
import {NavLink, Link} from "react-router-dom";

const Header = () => {

  return(
    <header id="header">
      <div id="header_wrapper">
          <Link className={"logo_wrapper"} id={"satec"} to={"/"}/>
          <Link className={"logo_wrapper"} id={"axa"} to={"/"}/>
          <Link className={"logo_wrapper"} id={"activites_sociales"} to={"/"}/>
      </div>
    </header>
  )
}

export default Header;

