import React, {useState, useEffect, useRef} from 'react';
import BirthDayField from "../form/BirthDayField";
import {useForm} from "react-hook-form";
import CustomFieldText from "../form/CustomFieldText";

import * as Pattern from '../form/form_pattern_helper.jsx';
import {checkOpeStop} from '../loader/redirectLoader'

const Home = () => {

    const {register, handleSubmit, formState: { errors }, setValue, clearErrors, setError } = useForm({validateCriteriaMode: "all"})
    const submitRef = useRef(null)

    const [showMerci, setShowMerci] = useState(false)
    const onSubmit = (data) => {
//console.log(data)
        submitRef.current.setAttribute("disabled", "disabled");

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("action", "participate");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    setShowMerci(true);
                    document.getElementById("content_index_wrapper").classList.add("merci")
                } else {

                    switch(json.message){
                        case  "#recaptcha" :
                            alert("Veuillez cocher la case \"Je ne suis pas un robot\" pour continuer.");
                            break;

                        case "#already_participate" :
                            alert("L'opération est limité à une participation par personne.");
                            break;

                        case "#forbidden_cp" :
                            alert("Cette opération est limitée aux résidents de France Métropolitaine et de Corse uniquement !");
                            break;

                        default :
                            alert(json.message);
                    }
                    //setFormError(json.error);


                    submitRef.current.removeAttribute("disabled");
                }

            });
    }


    return(
        <div className={`game_wrapper`}>
            {!showMerci &&
            <>
                <div id={"introduction"}>
                    <div>
                        {!checkOpeStop() &&
                        <>
                            <h1>Jeu concours</h1>
                            <p>
                                Parce que la prévention routière <br/>
                                se fait de multiples façons, <br className={"d-lg-none"}/>AXA Partenariats, <br className={"d-none"}/>
                                la CCAS <br className={"d-lg-none"}/>et SATEC vous offrent <br className={"d-lg-none"}/>la possibilité <br className={"d-none"}/>
                                de <strong>gagner <br className={"d-lg-none"}/>10 stages de conduite</strong> <br/>
                                d’une journée pour appréhender <br className={"d-lg-none"}/>la route <br className={"d-none"}/>
                                sereinement, <br className={"d-lg-none"}/>même dans les conditions <br/>
                                les plus difficiles.
                            </p>
                        </>}
                        {checkOpeStop() &&
                            <p className={"ope_stop"}>
                                Notre jeu concours est désormais terminé.<br/><br/>
                                Un grand merci à toutes celles et ceux qui ont participé et tenté leur chance ! 🍀<br/><br/>
                                📅 Résultats : le tirage au sort sera effectué le jeudi 31/10/2024. Les heureux gagnants seront ensuite contactés par email à l’adresse renseignée dans le formulaire de participation.<br/><br/>
                                À très bientôt pour de nouveaux concours ! 🎉<br/><br/>

                                Pour retrouver l'ensemble de nos prestations, rendez-vous sur notre <a href={"https://ccas.satecassur.com/"} target={"_blank"}>site</a>.<br/>
                                Pour suivre notre actualité et participer au prochain jeu, rendez-vous sur notre compte <a href={"https://www.instagram.com/ccas.satec/"} target={"_blank"}>Instagram</a> et/ou <a href={"https://www.facebook.com/"} target={"_blank"}>Facebook</a>.
                            </p>
                        }
                    </div>
                </div>
                {!checkOpeStop() && <div id={"form_wrapper"}>
                    <div className={"form_title d-lg-none"} id={"mobile_title"}>
                        <span>Tentez votre chance</span><br/>
                        <span>pour gagner un stage</span><br/>
                        en vous inscrivant<br/>
                        au tirage au sort<br/>
                        ci-dessous :
                    </div>

                    <div className={"form_title d-none d-lg-block"} id={"desktop_title"}>
                        Tentez votre chance pour <span>gagner</span><br/>
                        <span>un stage</span> en vous inscrivant<br/>
                        au tirage au sort ci-dessous :
                    </div>

                    <form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                        <CustomFieldText name="nom" register={register("nom", {required: true})} placeholder={"Nom"} error={[errors.nom && errors.nom.type === "required" && "Veuillez renseigner cette information pour continuer"]}/>
                        <CustomFieldText name="prenom" register={register("prenom", {required: true})} placeholder={"Prénom"}
                                         error={[errors.prenom && errors.prenom.type === "required" && "Veuillez renseigner cette information pour continuer"]}/>
                        <CustomFieldText name="mail" register={register("mail", {
                            required: true,
                            pattern: Pattern.emailPattern,
                        })} placeholder={"Adresse E-mail"} error={[
                            errors.mail && errors.mail.type === "required" && "Veuillez renseigner cette information pour continuer",
                            errors.mail && errors.mail.type === "pattern" && "Veuillez renseigner une adresse e-mail valide pour continuer",
                        ]}/>

                        <BirthDayField
                            label={"Date de naissance"}
                            register={register}
                            errors={errors}/>

                        <div className={"text-center"}>
                            <button className={"cta"} ref={submitRef}>Je participe !</button>
                        </div>

                        <div id={"optin"}>
                            <div className={"optin_wrapper"}>
                                <input type={"checkbox"} name={"optin_reglement"} id={"optin_reglement"} {...register("optin_reglement", {required: "Veuillez lire et accepter le règlement pour continuer"})}/>
                                <label htmlFor={"optin_reglement"}>En cochant cette case, je certifie avoir plus de 18 ans et accepter le règlement du jeu concours.</label>
                                <div className="error">{errors.optin_reglement && errors.optin_reglement.message}</div>
                            </div>

                            <div className={"optin_wrapper"}>
                                <input type={"checkbox"} name={"optin_rgpd"} id={"optin_rgpd"} {...register("optin_rgpd", {required: "Veuillez cocher cette case pour continuer"})}/>
                                <label htmlFor={"optin_rgpd"}>En cochant cette case, j'accepte que mes données personnelles soient partagées avec la société SATEC, dans le cadre de ma participation au jeu concours. Les données personnelles
                                    me
                                    concernant, collectées et traitées dans le cadre du jeu concours ne seront pas transmises à d'autres organismes ou sociétés et seront détruites à l'issue de la période de déroulement du jeu
                                    concours.</label>
                                <div className="error">{errors.optin_rgpd && errors.optin_rgpd.message}</div>
                            </div>
                        </div>

                    </form>

                </div>}
            </>
            }
            {showMerci &&
                <div id={"merci"}>
                    <div>merci pour votre participation</div>
                    <p>un mail de confirmation vous sera envoyé.</p>
                </div>
            }
        </div>
    )
};


export default Home;