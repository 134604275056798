import {RouterProvider} from "react-router-dom";
import router from "./component/routes/Router";
import history from "./component/routes/history";

import React from "react";

function App() {

    return (
        <RouterProvider router={router} history={history}/>
    );
}

export default App;
