import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import Header from "./header"
import Footer from "./footer"

import ScrollTop from "./_scrollTop.jsx"
import ScrollToHashElement from "./_ScrollToHashElement";

import { Helmet } from 'react-helmet';
import {checkOpeStop} from "./loader/redirectLoader";

const Layout = () => {

    const [pageName, setPageName] = useState("")
    const {pathname, hash} = useLocation();

    const [seoState, setSeoState] = useState({
        "title" : "",
        "description" : "",
        "keywords" : ""
    })

    /**
     * Set page name from the windows path
     */
   useEffect(() => {

       //set page name for wrapper
       const currentPageName = pathname.replace('/','').split("/").join("_")
       setPageName(currentPageName === "" ? 'index':currentPageName)
       window.scrollTo(0, 0)

       setSeoState({
           title : "SATEC - jeu concours",
           description : "Tenter de gagner un stage de conduite avec Axa",
           keywords : ""
       })

    },[pathname,hash])

    return (
        <>
            <div className="page_wrapper" id={"page_" + pageName + "_wrapper"}>
                <Helmet>
                    <title>{seoState.title}</title>
                    <meta name="description" content={seoState.description} />
                    <meta name="keyword" content={seoState.keywords} />
                </Helmet>

                <Header/>

                <div className={`content_wrapper ${checkOpeStop() ? "closed" : ""}`} id={"content_" + pageName + "_wrapper"}>
                    <Outlet/>
                </div>

                <Footer/>

                <ScrollTop/>
                <ScrollToHashElement/>
            </div>
        </>
    );
}

export default Layout;
