import React from 'react';

const Footer = () => {

  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div id={"footer_menu"}>
          <nav>
            <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du jeu concours</a>
            <a href="https://satecassur.com/protection-des-donnees/" target={"_blank"}>Politique de Confidentialité</a>
          </nav>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
