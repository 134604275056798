import {createBrowserRouter} from "react-router-dom";

import Layout from "../_Layout"
import ErrorPage from "../pages/404";

import Home from "../pages/home";
import {isOpeStartRedirect, isOpeStopRedirect} from "../loader/redirectLoader";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        children : [
            {
                path: "/",
                element : <Home/>
            }
        ]
    },
]);

export default router